import { useSWRConfig } from 'swr';
import { useTranslation } from 'react-i18next';
import { useMemo, useEffect, useReducer, useCallback } from 'react';

import { useRouter } from 'src/routes/hooks';

import axios, { endpoints } from 'src/utils/axios';

import api from 'src/api';

import { setSession } from './utils';
import { AuthContext } from './auth-context';
import { AuthUserType, ActionMapType, AuthStateType } from '../../types';

// ----------------------------------------------------------------------

// NOTE:
// We only build demo at basic level.
// Customer will need to do some extra handling yourself if you want to extend the logic and other features...

// ----------------------------------------------------------------------

enum Types {
  INITIAL = 'INITIAL',
  LOGIN = 'LOGIN',
  REGISTER = 'REGISTER',
  LOGOUT = 'LOGOUT',
}

type Payload = {
  [Types.INITIAL]: {
    user: AuthUserType;
  };
  [Types.LOGIN]: {
    user: AuthUserType;
  };
  [Types.REGISTER]: {
    user: AuthUserType;
  };
  [Types.LOGOUT]: undefined;
};

type ActionsType = ActionMapType<Payload>[keyof ActionMapType<Payload>];

// ----------------------------------------------------------------------

const initialState: AuthStateType = {
  user: null,
  loading: true,
};

const reducer = (state: AuthStateType, action: ActionsType) => {
  if (action.type === Types.INITIAL) {
    return {
      loading: false,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGIN) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.REGISTER) {
    return {
      ...state,
      user: action.payload.user,
    };
  }
  if (action.type === Types.LOGOUT) {
    return {
      ...state,
      user: null,
    };
  }
  return state;
};

// ----------------------------------------------------------------------

const STORAGE_KEY = 'positeams-access-token';

type Props = {
  children: React.ReactNode;
};

export function AuthProvider({ children }: Props) {
  const router = useRouter();
  const { mutate } = useSWRConfig();
  const { i18n } = useTranslation();

  const [state, dispatch] = useReducer(reducer, initialState);

  const initialize = useCallback(async () => {
    try {
      const accessToken = localStorage.getItem(STORAGE_KEY);

      if (accessToken) {
        const values = {
          token: accessToken,
        };
        const res = await api.auth.verifyToken(values);
        if (res.status !== 200) {
          throw new Error('Invalid token');
        }

        setSession(accessToken);

        const user = res.data;

        // console.log('user', user);

        dispatch({
          type: Types.INITIAL,
          payload: {
            user,
          },
        });
      } else {
        dispatch({
          type: Types.INITIAL,
          payload: {
            user: null,
          },
        });
      }
    } catch (error) {
      console.error(error);
      dispatch({
        type: Types.INITIAL,
        payload: {
          user: null,
        },
      });
    }
  }, []);

  useEffect(() => {
    initialize();
  }, [initialize]);

  // LOGIN
  const login = useCallback(async (email: string, password: string) => {
    const values = {
      email,
      password,
    };

    const res = await api.auth.login(values);

    if (res.status !== 200 && res.data?.auth) {
      throw new Error('Invalid email or password');
    }

    const { token, mfa_login, multiple_companies, extra_companies, ...user } = res.data;

    if (mfa_login) {
      return { mfa_required: true, multiple_companies: false, extra_companies: [] };
    }

    if (multiple_companies) {
      return { mfa_required: false, multiple_companies: true, extra_companies };
    }

    if (token) {
      setSession(token);

      dispatch({
        type: Types.LOGIN,
        payload: {
          user: {
            ...user,
            accessToken: token,
          },
        },
      });

      const locale = user.locale === 'ar' ? 'fr' : user.locale;
      i18n.changeLanguage(locale);
    }

    return { mfa_required: false, multiple_companies: false, extra_companies: [] };
  }, []);

  // MFA LOGIN
  const mfaLogin = useCallback(async (email: string, code: number) => {
    const values = {
      email,
      mfa_code: code,
    };

    const res = await api.auth.mfaLogin(values);

    if (res.status !== 200 && res.data?.auth) {
      throw new Error('Invalid email or password');
    }

    const { token, multiple_companies, extra_companies, ...user } = res.data;

    if (multiple_companies) {
      return { multiple_companies: true, extra_companies };
    }

    setSession(token);

    dispatch({
      type: Types.LOGIN,
      payload: {
        user: {
          ...user,
          accessToken: token,
        },
      },
    });

    const locale = user.locale === 'ar' ? 'fr' : user.locale;
    i18n.changeLanguage(locale);

    return { multiple_companies: false, extra_companies: [] };
  }, []);

  // SELECT COMPANY
  const selectCompany = useCallback(async (email: string, companyId: string) => {
    const values = {
      email,
      companyId,
    };

    const res = await api.auth.selectCompany(values);

    if (res.status !== 200 && res.data?.auth) {
      throw new Error('Something went wrong on selecting company');
    }

    const { token, ...user } = res.data;

    if (token) {
      setSession(token);

      dispatch({
        type: Types.LOGIN,
        payload: {
          user: {
            ...user,
            accessToken: token,
          },
        },
      });

      const locale = user.locale === 'ar' ? 'fr' : user.locale;
      i18n.changeLanguage(locale);
    }
  }, []);

  // REGISTER
  const register = useCallback(
    async (email: string, password: string, firstName: string, lastName: string) => {
      const data = {
        email,
        password,
        firstName,
        lastName,
      };

      const res = await axios.post(endpoints.auth.register, data);

      const { accessToken, ...user } = res.data;

      localStorage.setItem(STORAGE_KEY, accessToken);

      dispatch({
        type: Types.REGISTER,
        payload: {
          user,
        },
      });
    },
    []
  );

  // LOGOUT
  const logout = useCallback(async () => {
    setSession(null);
    dispatch({
      type: Types.LOGOUT,
    });

    // clear SWR cache
    mutate(
      (key) => true, // which cache keys are updated
      undefined, // update cache data to `undefined`
      { revalidate: false } // do not revalidate
    );

    router.replace('/auth/jwt/login');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // REFRESH USER DATA
  const refreshUserData = useCallback(async () => {
    initialize();
  }, [initialize]);

  // ----------------------------------------------------------------------

  const checkAuthenticated = state.user ? 'authenticated' : 'unauthenticated';

  const status = state.loading ? 'loading' : checkAuthenticated;

  const memoizedValue = useMemo(
    () => ({
      user: state.user,
      method: 'jwt',
      loading: status === 'loading',
      authenticated: status === 'authenticated',
      unauthenticated: status === 'unauthenticated',
      //
      login,
      mfaLogin,
      selectCompany,
      register,
      logout,
      refreshUserData,
    }),
    [login, mfaLogin, logout, register, refreshUserData, state.user, status]
  );

  return <AuthContext.Provider value={memoizedValue}>{children}</AuthContext.Provider>;
}
