import { useState, useEffect } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';

import { env } from 'src/env-config';

// ----------------------------------------------------------------------

type Props = {
  children: React.ReactNode;
};

export default function AuthModernCompactLayout({ children }: Props) {
  const [isAwb, setIsAwb] = useState(false);

  useEffect(() => {
    console.log('location', window.location.hostname);
    if (window?.location?.hostname?.includes('hrsurvey')) {
      setIsAwb(true);
    }
  }, []);

  return (
    <Box
      component="main"
      sx={{
        py: 12,
        display: 'flex',
        minHeight: '100vh',
        textAlign: 'center',
        px: { xs: 2, md: 0 },
        position: 'relative',
        alignItems: 'center',
        justifyContent: 'center',
        '&:before': {
          width: 1,
          height: 1,
          zIndex: -1,
          content: "''",
          opacity: 0.24,
          position: 'absolute',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center center',
          backgroundImage: `url(${env === 'attijari' ? '/speakup' : ''}/assets/background/${
            isAwb ? 'awb_overlay.jpg' : 'overlay_4.jpg'
          })`,
        },
      }}
    >
      <Card
        sx={{
          py: 5,
          px: 3,
          width: 480,
        }}
      >
        {children}
      </Card>
    </Box>
  );
}
