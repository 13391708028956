import { forwardRef } from 'react';

import Link from '@mui/material/Link';
import Box, { BoxProps } from '@mui/material/Box';

import { RouterLink } from 'src/routes/components';

import PositeamsEngageLogo from 'src/assets/logo/positeams-engage.png';
import PositeamsDarkLogo from 'src/assets/logo/positeams-logo-dark.png';
import PositeamsSmallLogo from 'src/assets/logo/positeams-logo-small.png';
import PositeamsWhiteLogo from 'src/assets/logo/positeams-logo-white.png';

import { useSettingsContext } from '../settings';

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
  disabledLink?: boolean;
  small?: boolean;
  width?: number | string;
  engage?: boolean;
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
  ({ disabledLink = false, width = 140, small = false, engage = false, sx, ...other }, ref) => {
    const settings = useSettingsContext();

    // OR using local (public folder)
    // -------------------------------------------------------

    const isMini = settings.themeLayout === 'mini' || small;
    const isDark = settings.themeMode === 'dark';

    let logo = null;
    if (isMini) {
      logo = (
        <Box
          component="img"
          src={PositeamsSmallLogo}
          sx={{ width: 38, cursor: 'pointer', ...sx }}
        />
      );
    } else if (engage) {
      logo = (
        <Box component="img" src={PositeamsEngageLogo} sx={{ width, cursor: 'pointer', ...sx }} />
      );
    } else if (isDark) {
      logo = (
        <Box component="img" src={PositeamsDarkLogo} sx={{ width, cursor: 'pointer', ...sx }} />
      );
    } else {
      logo = (
        <Box component="img" src={PositeamsWhiteLogo} sx={{ width, cursor: 'pointer', ...sx }} />
      );
    }

    if (disabledLink) {
      return logo;
    }

    return (
      <Link component={RouterLink} href="/" sx={{ display: 'contents' }}>
        {logo}
      </Link>
    );
  }
);

export default Logo;
