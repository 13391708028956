// import axios, { fetcher, endpoints } from 'src/utils/axios';
import axios from 'src/utils/axios';

export async function login(values: object) {
  const res = await axios.post('/auth/login', values);

  return res;
}

export async function mfaLogin(values: object) {
  const res = await axios.post('/auth/login_mfa', values);

  return res;
}

export async function selectCompany(values: object) {
  const res = await axios.post('/auth/select-company', values);

  return res;
}

export async function verifyToken(values: object) {
  const res = await axios.post('/auth/verify-token', values);

  return res;
}

export async function hasPermission(route: string) {
  const res = await axios.post('/haspermission', { route });

  return res;
}

export async function forgotPassword(values: object) {
  const res = await axios.post('/auth/reset', values);

  return res;
}

export async function verifyPasswordToken(values: object) {
  const res = await axios.post('/auth/verify_password_token', values);

  return res;
}

export async function updatePassword(values: object) {
  const res = await axios.post('/auth/update_password', values);

  return res;
}
