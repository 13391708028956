export function groupQuestionsPulse(questions: any[]) {
  return questions.reduce(
    (r, item) => {
      let current = r.hash[item.pulse_question_category?.pulse_question_categories_trans[0]?.name];

      if (!current) {
        r.hash[item.pulse_question_category?.pulse_question_categories_trans[0]?.name] = {
          category: item.pulse_question_category?.pulse_question_categories_trans[0].name,
          id: item.pulse_question_category?.id,
          items: [],
        };
        current = r.hash[item.pulse_question_category?.pulse_question_categories_trans[0]?.name];

        r.arr.push(current);
      }
      current.items.push({
        id: item.id,
        question: item.pulse_questions_trans[0].description,
        question_trans: item.pulse_questions_trans,
        active: true,
        category: item.pulse_question_category?.pulse_question_categories_trans[0].name,
        subcat: item.pulse_question_subcat?.pulse_question_subcats_trans[0].name,
        subcat_id: item.pulse_question_subcat?.id,
        gallup: item.gallup,
        custom: item.custom,
        questionOrder: item.questionOrder,
      });

      return r;
    },
    { hash: {}, arr: [] }
  ).arr;
}

export function groupCustomQuestionsPulse(questions: any[]) {
  const list = questions.reduce(
    (r, item) => {
      const category = item.pulse_question_category
        ? item.pulse_question_category?.pulse_question_categories_trans[0]?.name
        : item.custom_pulse_question_category?.name;

      let current = r.hash[category];

      if (!current) {
        r.hash[category] = {
          category,
          id: item.pulseQuestionCategoryId
            ? item.pulseQuestionCategoryId
            : item.customPulseQuestionCategoryId,
          items: [],
        };
        current = r.hash[category];

        r.arr.push(current);
      }
      current.items.push({
        id: item.id,
        question: item.description,
        question_trans: item.custom_pulse_questions_trans,
        active: item.active || true,
        category,
        subcat:
          item.pulse_question_subcat?.pulse_question_subcats_trans[0].name ||
          item.custom_pulse_question_subcat?.name ||
          '',
        subcat_id: item.customPulseQuestionSubcatId || item.pulseQuestionSubcatId || null,
        gallup: item.gallup,
        custom: item.custom,
        questionOrder: item.questionOrder,
      });

      return r;
    },
    { hash: {}, arr: [] }
  ).arr;

  list.forEach((item: any, index: number) => {
    if (item.category === 'Engagement') {
      item.items.forEach((question: any, j: number) => {
        if (question.subcat === 'Engagement') {
          const temp = item.items[0];
          item.items[0] = question;
          item.items[j] = temp;
        }
      });
      const temp = list[0];
      list[0] = item;
      list[index] = temp;
    }
  });

  return list;
}

export function groupCategories(categories: any[]) {
  const result = categories.reduce(
    (r, item) => {
      try {
        let current = r.hash[item.catname];

        if (!current) {
          r.hash[item.catname] = {
            id: item.cat_id,
            name: item.catname,
            custom: item.custom,
            pyramidOfNeedId: item.pyramidOfNeedId,
            subcats: [],
          };

          current = r.hash[item.catname];

          r?.arr.push(current);
        }

        if (item.subcat_id) {
          current.subcats.push({
            id: item.subcat_id,
            name: item.subcatname,
            custom: item.custom_subcat,
          });
        }

        return r;
      } catch (err) {
        return null;
      }
    },
    { hash: {}, arr: [] }
  );

  return result.arr;
}
